module.exports = class ArticleView {


    constructor(options) {
        var defaults = {
            readonlyClass: ".article-view__readonly",
            editClass: ".article-view__form",
            editBtnClass: ".article-view__edit-btn",
            cancelBtnClass: ".article-view__cancel-btn",
            hiddenClass: 'hidden'
        };

        let populated = Object.assign(defaults, options);
        for (let key in populated) {
            if (populated.hasOwnProperty(key)) {
                this[key] = populated[key];
            }
        }

        this.addEditBtnEventListener();
        this.addCancelBtnEventListener();
    }

    addEditBtnEventListener(){
        var btn = document.querySelector(this.editBtnClass);

        if(btn) {
            btn.addEventListener('click', (e) => {
                var readOnlyView = document.querySelector(this.readonlyClass);
                var editView = document.querySelector(this.editClass);

                if(readOnlyView && editView){
                    editView.classList.remove(this.hiddenClass);
                    readOnlyView.classList.add(this.hiddenClass);
                }
            });
        }
    }

    addCancelBtnEventListener(){
        var btn = document.querySelector(this.cancelBtnClass);
        console.log(btn);

        if(btn) {
            btn.addEventListener('click', (e) => {
                var readOnlyView = document.querySelector(this.readonlyClass);
                var editView = document.querySelector(this.editClass);

                if(readOnlyView && editView){
                    readOnlyView.classList.remove(this.hiddenClass);
                    editView.classList.add(this.hiddenClass);
                }
            });
        }
    }
}